$light: #fff;
$primary-color: #0181E4;
$secondary-color: #1bbb1b;
$success: #1bbb1b;
$black: #000;
$alert: #c7254e;
$border-color: #dedede;
$primary-font: 'Roboto', sans-serif;
$secondary-font: 'Volkhov', serif;
$icon-font: 'themefisher-font';
$header-height: 80px;
$logo-width: 100px;
$dbt-blue: #267DC0;
$dbt-green: #36B449;