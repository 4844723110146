@import '../../../scss/shared.scss';

.form-edit {
  .form-group {
    margin-bottom: 15px;

    .form-label {
      font-weight: bold;
    }
  }

  div.button-row {
    .btn {
      margin: 20px 0;
      width: 100%;
      font-size: 1.2em;
      font-weight: bold;
    }
    .save-ride-button {
      
      
     
      svg {
        font-size: 1.1em;
      }
      span {
        position: relative;
        top: 2px;
      }
    }
  }
  
}

.priceNote {
  padding: 15px;
  padding-top: 5px;
  .note {
    font-weight: bold;
    margin-right: 5px;
  }
}