@import '../../scss/shared.scss';

.header {
  position: relative;
  margin-top: 30px;
  font-size: 2em;
  text-align: center;
  .back-button {
    position: absolute;
    left: 5px;
  }
  svg {
    font-size: 1.5em;
  }
  span {
    position: relative;
    top: 3px;
    margin-left: 10px;
  }
}