@import '../../scss/shared.scss';

.rvCard {
  .rvCardDescription {
    background-color: #fff;
    padding: 30px 40px 0 40px;

    p {
      font-size: 1.2em;
      color: black;
      margin-bottom: 0;
    }

    div.row:nth-child(2) {
      padding-bottom: 20px;
    }
  }

  .rvCardDetails {
    background-color: white;
    padding: 20px 40px;
    padding-top: 0;
  }

  .rvCardImage {
    padding: 0;

    // > div {
    //   position: relative;
    //   width: 100%;
    //   height: 100%;

    //   img {
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    //     object-fit: cover;
    //     font-family: "object-fit: cover"
    //   }
    // }
  }

  .detailsBlock {
    padding-top: 20px;
    width: 100%;

    div {
      width: 1005;
      display: block;
      text-align: center;
    }

    .detailsTitle {
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: solid 3px $dbt-green;
    }

    .detailsContent {
      padding-top: 6px;
    }
  }
}

@include lg {
  .rvCard {
    .rvCardDescription {
      padding-right: 0;
    }
    .rvCardDetails {
      span {
        text-align: left;
      }
      padding-top: 20px;
    }
  }
}

@include xxl {
  .rvCard {
    .rvCardImage > div {
      max-height: 400px;
    }
  }
}