@import '../../scss/shared.scss';

.rideImage {
  div {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      font-family: "object-fit: cover"
    }
  }
}

.imgSlider {

  .col-12 {}

  div {
    height: 675px;
  }

}

.info {

  .fevo-button {
    width: 100%;
  }

  h2 {
    padding: 15px 0;
    font-weight: bold;
  }

  .book {
    display: flex;
    justify-content: center;
    // padding: 10px;
  }

  .summary {
    margin-bottom: 15px;

    li {
      margin-bottom: 5px;

      svg {
        font-size: 1.25em;
      }

      span {
        // font-size: 1.2em;
        margin-left: 4px;
        position: relative;
        top: 2px;

        &.summary-label {
          font-weight: bold;
        }
      }

      &.loc svg {
        fill: red;
      }

      &.meetup svg {
        fill: darkblue;
      }
    }
  }

  .poi {
    .feature {
      flex: 1;
      text-align: center;

      .feature-img {
        margin: auto;
        text-align: center;
        // height: 200px;
        width: 75%;

        // background-color: gray;
        img {
          border-radius: 15px;
          box-shadow:
            2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
            6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
            12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
            22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
            41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
            100px 100px 80px rgba(0, 0, 0, 0.07)
        }
      }

      .feature-text {
        margin: 0.5em;
        font-weight: bold;
      }
    }
  }

  div.description {

    p {
      font-size: 1.5em;
    }

  }
}