.add-first {
  margin-left: 20px;
}
.imageUploading {
  span {
    margin-left: 5px;
  }
}
div.mainImagePreview {
  display: inline-block;
  position: relative;
  img {
    width: 175px;
  }

  button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}