/* Provide sufficient contrast against white background */
body {
  background-color: rgb(248, 249, 250);
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

footer {
  text-align: center;
  font-size: 0.8em;
  margin-bottom: 2em;
}
