.save-seo-button {
  margin-bottom: 20px;
     
  svg {
    font-size: 1.1em;
  }
  span {
    position: relative;
    top: 2px;
    padding-left: 5px;
  }
}