@import '../../../scss/shared.scss';

.rides-admin {
  .rides {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    .ride {
      position: relative;
      width: auto;
      width: 320px;
      height: 240px;
      // max-width: 320px;
      cursor: pointer;
      margin: 10px 20px;
      &.ride-new {
        background-color: $dbt-green;
        border-radius: 10px;
        .menu button {
          display: none;
        }
        .fa-plus {
          position: absolute;
          top: 120px;
          left: 160px;
          transform: translateX(-50%) translateY(-50%);
          font-size: 6em;
          color: white;
        }
      }
      img {
        display: block;
        border: solid 2px $dbt-green;
        border-radius: 10px;
      }
      .noImage {
        width: 320px;
        height: 240px;
        background-color: gray;
        border-radius: 10px;
        border: solid 2px $dbt-green;
        color: white;
        font-size: 1.3em;
        font-weight: bold;
      }
      .title {
        padding: 0px 7px;
        margin: 0;
        position: absolute;
        top: 8px;
        left: 10px;
        color: white;
        font-weight: bold;
        font-size: 1.4em;
        max-width: 75%;
        background-color: rgba(0,0,0,0.3);
        border-radius: 5px;
      }
      .menu {
        position:absolute;
        bottom: 12px;
        width: 100%;
        padding: 0 12px;
        // height: 20px;
        // background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
          font-size: 1.1em;
          padding: 6px 10px;
          span {
            position: relative;
            top: 2px;
            padding: 0;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@include lg {
  .rides-admin {
    .rides {
      flex-flow: row wrap;
    }
  }
}