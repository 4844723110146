td.buttons {
  padding: 4px;
  .btn {
    svg {
      margin-left: 3px;
      position: relative;
      top: -2px;
      font-size: 1.1em;
    }
    font-weight: bold;
    padding-right: 3px;
    margin-right: 8px;
  }
}