@import '../../scss/shared.scss';

.menu-admin {
  margin: inherit;
}

@include md {
  .menu-admin {
    margin-top: 40px;
  }
}

