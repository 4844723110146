@import '../scss/shared.scss';

.carousel {
  height: 100%;

  .carousel-inner {
    height: 100%;
    
    > div {
      position: relative;
      width: 100%;
      height: 100%;
  
      img {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

@include lg {
  .carousel-inner > div img {
    position: absolute;
  }
}
