.slider {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  padding: 170px 0 200px;
  position: relative;
  @include tablet {
    background-attachment: scroll;
    padding: 150px 0;
  }

  .block {
    color: #E3E3E4;
    text-align: center;

    h1 {
      font-weight: 100;
      font-size: 45px;
      line-height: 1.33em;
      letter-spacing: 0.2em;
      padding-bottom: 15px;
      text-transform: uppercase;

      @include tablet {
        font-size: 35px;
      }

      @include mobile {
        font-size: 28px;
      }

      @include mobile-xs {
        font-size: 26px;
      }
    }

    p {
      margin-bottom: 30px;
      color: #b9b9b9;
      font-size: 18px;
      line-height: 1.5em;
      font-weight: 300;

      @include mobile {
        font-size: 14px;
      }
    }

    @include mobile {
      .btn {
        font-size: 12px;
      }
    }
  }
}
