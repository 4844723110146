@import '../../../scss/shared.scss';

div.flyerContainer {
}

div.flyerContainer button {
}

img.flyer {
  display: block;
  
  margin-bottom: 25px;
}

.addNewRide.btn {
  font-size: 1.1em;
  font-weight: bold;
  svg {
    font-size: 1.2em;
    position: relative;
    top: -2px;
  }
}