.dropzone-container {
  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;

    p {
      margin: 0;
    }
  }

  .thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;

    .thumb {
      display: inline-flex;
      border-radius: 2px;
      border: 1px solid #eaeaea;
      margin-bottom: 8px;
      margin-right: 8px;
      width: 100px;
      // height: 100px;
      padding: 4px;
      box-sizing: border-box;

      .thumb-inner {
        display: flex;
        min-width: 0;
        overflow: hidden;

        img {
          display: block;
          width: auto;
          height: 100%;
        }
      }
    }
  }

  .btn-upload {
    width: 100%;
    margin-bottom: 15px;
  }

  .progress-bar {
    margin: 0 0 15px 0;
    height: 15px; 
    transition: width 1s ease !important;
  }
}