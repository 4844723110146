.rvHeader.row {
  background-color: #267DC0;

  padding-top: 30px;
  padding-bottom: 20px;

  >div {
    padding: 0 20px;
  }

  .title {
    span {
      display: block;
    }

    .caption {
      color: white;
    }

    .name {
      color: white;
      font-family: Roboto;
      font-weight: bold;
      font-size: 2em;
    }
  }

  .short-description {
    color: white;
    display: flex;
    align-items: center;
  }
}