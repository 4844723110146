.uploadingFlyer {
  text-align: center;
  svg {
    font-size: 5em;
  }
  div {
    margin-top: 10px;
    font-style: italic;
  }
}

.spinner {
  animation: spin infinite 1s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.flyerError {
  position:relative;
  top:15px;
  text-align: center;
  color: red;
}