@import '../../scss/shared.scss';

.rvFaq {
  padding: 40px;
  background-color: $dbt-blue;
  color: white;

  h2 {
    font-size: 1.9em;
    font-weight: bold;
  }

  .faqCol {
    // background-color: white;
    // color: black;
    // border-radius: 4px;
    display: flex;
    flex-wrap: wrap;

    .faqBlock {
      display: inline-block;
      min-width: 350px;
      max-width: 500px;
      padding: 20px;
      padding-left: 0;
      padding-right: 30px;

      .questionBlock {
        font-weight: bold;
        border-bottom: solid 3px $dbt-green;

        span:first-child {
          font-size: 1.2em;
        }
      }

      .answerBlock {
        margin-top: 5px;
        span:first-child {
          font-weight: bold;
          font-size: 1.2em;
        }
      }
    }
  }
}