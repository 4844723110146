@import '../scss/shared.scss';

a.fevo-button {
  background-color: $dbt-blue;
  color: white !important;
  font-weight: bold;

  &.fevo-button-header {
    position: absolute;
    right: 10px;
    top: 13px;
    padding: 14px 14px;
  }

  &.fevo-button-page {
    margin-top: 20px;
    width: 100%;
  }
}