$height: 1.5rem;

.skillBlock {
  .progress {
    height: $height;
  }
  .progress-bar {
    height: $height;
    line-height: $height;
  }
}