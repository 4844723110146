@import '../../../scss/shared.scss';

.ride-images {
  margin-top: 25px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  div {
    min-width: 120px;
    max-width: 240px;
    height: auto;
    margin-right: 10px; 
    margin-bottom: 10px;
    border: solid 1px black;
    padding: 3px;
  }
}