@import '../../scss/shared.scss';

div.menu-admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;

  .admin-button {
    cursor: pointer;
    margin-bottom: 20px;
    
    width: 80%;
    padding-top:15px;
    
    svg {
      font-size: 4em;
      margin-bottom: 10px;
    }

    span {
      font-size: 2em;
    }
  }
}

@include md {
  div.menu-admin {
    flex-flow: row wrap;
    justify-content: center;
    
    .admin-button {
      width: 275px;
      margin-right: 20px;
    }
  }
}