@import '../scss/shared.scss';

header {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: $header-height;
  background: rgb(248, 249, 250);
  // background-color: $dbt-blue;
  color: white !important; 
  padding: 5px 0;

  .branding {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: $logo-width;
    z-index: 11;
    transition: left 0.5s, transform 0.5s;
  }

  li {
    margin-top: 10px;

    &.nav-item.dropdown {
      position:relative;
      top:-2px;
    }
  }

  a,
  button {
    text-transform:uppercase;
    color: #000 !important;

    font : {
      weight: 500;
      size: 16px;
    }
    
    &:hover,
    &:focus {
      color: #000;
      background: transparent;
    }
    &.current {
      color: #000;
      pointer-events: none;
      cursor: default;
    }
    &.current-parent {
      color: #000;
    }
  }

  .dropdown-menu {
    background-color: rgb(248, 249, 250);
    border: none;
    button, a {
      text-transform: uppercase;
      &.dropdown-item {
        color: #000;
      }
    }
  }
}

nav {
  padding-left: 15px;
}

@include sm {
  header {
    display: block;

    >div.inner {
      padding-left: calc(#{$logo-width} + 2rem);
    }

    .branding {
      display: block;
      padding: 0;
      left: 0;
      transform: none;
      margin: 0 1rem;
      z-index: 1;

      img {
        display: block;
        width: 100px;
      }
    }

    .navbar {
      .navbar-nav {
        position: relative;
        margin-top: 7.5px;
        padding-top: 10px;

        li {
          margin-top: 0;
        }

        a {
          padding: 10px 15px 0 15px;
        }

        &>li.dropdown.nav-item>a {
          position: relative;
          top: -8px;
        }

        .nav-socials {
          position:relative;
          top:-2px;
          color: black;
          font-weight: bold;
          margin-left: 50px;
          img {
            height: 26px;
            padding-right: 10px;
          }
        }
      }

      .dropdown-menu {
        border-radius: 5px;
        
        // left: -5px;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);

        a {
          // text-transform: none;
          // font-weight: normal;
          // color: #808080;
          // padding: 10px 20px;
          //@include transition-multi (color 0.1s ease, padding 0.3s ease);
          padding-top: 5px;
          padding-bottom: 5px;

          &:hover {
            cursor: pointer;
            background: $dbt-green;
            color: white;
            // padding-left: 25px;
          }
        }

        a.current {
          padding-left: 25px;
          color: $light;
          background: $primary-color;
        }
      }
    }
  }
}