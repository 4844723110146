.contact-form {
  margin-top: 30px;

  h3 {
    margin-bottom: 15px;
    padding-left: 5px;
  }

  .form-group {
    margin-bottom: 10px;
  }
}

.recaptcha {
  font: 14px Roboto, sans-serif;
  color: #dc3545;
  span {
    display: block;
    margin: 4px 0px 8px;
  }
}

.sendButtonContainer {
  text-align: right;
  .sendMessage.btn {
    margin-top: 8px;
    padding: 8px;
    font-weight: bold;
    font-size: 1.5em;
    width: 80%;
    height: 80%;
    
    svg {
      // font-size: 1.8em;
      margin-right: 3px;
      position: relative;
      top: -2px;
    }
  }
}

.socialMedia {
  padding: 20px 0;
  a {
    padding-right: 15px;
  }
}
