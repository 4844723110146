/*=== MEDIA QUERY ===*/
@mixin mobile-xs {
  @media(max-width: 400px) {
    @content;
  }
}
@mixin mobile {
  @media(max-width: 480px) {
    @content;
  }
}
@mixin tablet {
  @media(max-width: 768px) {
    @content;
  }
}
@mixin desktop {
  @media(max-width: 992px) {
    @content;
  }
}
@mixin large-desktop {
  @media(max-width: 1200px) {
    @content;
  }
}
@mixin sm {
  @media(min-width: 576px) {
    @content;
  }
}
@mixin md {
  @media(min-width: 768px) {
    @content;
  }
}
@mixin lg {
  @media(min-width: 992px) {
    @content;
  }
}
@mixin xl {
  @media(min-width: 1200px) {
    @content;
  }
}
@mixin xxl {
  @media(min-width: 1400px) {
    @content;
  }
}